import React from 'react'
import SampleCertificate from '../ReUsableComponent/SampleCertificate';

const AWSSampleCerticate = () => {
    
      return (
        <div>
         
            <SampleCertificate />
        </div>
      );
    };

export default AWSSampleCerticate