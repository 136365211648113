import React from "react";
import VideoComponent from "../ReUsableComponent/VideoComponent";

const DataEngineerVideo = () => {
  const testingData = {
    videoUrl: "https://youtu.be/CgkrM7Q4taw",
    courseDuration: "3 MONTHS",
    courseTitle: "Become a Data Engineer",
    courseDescription: "This comprehensive Data Engineer course covers foundational and advanced concepts, including SQL fundamentals, data warehousing, and hands-on training with Databricks and Spark. Over 40 sessions, you will learn about RDBMS, data types, joins, and window functions in SQL, as well as data handling in Python. Explore cloud technologies with Azure, mastering services such as Azure Data Factory, Azure DevOps, and Azure Synapse. Engage in practical exercises and projects that prepare you for real-world data engineering challenges and empower you to effectively manage and analyze data at scale.",
    expertsCount: 5,
    sessionsCount: 20,
    courseImage: "/Images/DataEngineer/DataEngineer.jpg"
  };

  return <VideoComponent {...testingData} />;
};

export default DataEngineerVideo;
