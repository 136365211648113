import React from 'react';
import CourseOverview from '../ReUsableComponent/CourseOverview';

const courses = [
  {
    title: "Data Engineer",
    title2: "Training Program!",
    description: "Dive into the world of Data Engineer with our comprehensive training program. This course covers the essential skills needed to design, build, and manage data pipelines that convert raw data into actionable insights. You'll learn about data warehousing solutions, ETL (Extract, Transform, Load) processes, and tools like Apache Spark, Hadoop, and AWS Redshift. By the end of the course, you'll have hands-on experience with data modeling, data integration, and big data technologies, empowering you to become a proficient Data Engineer. Join us to unlock the potential of data in your organization!"
  },
];

const DataEngineerCourseOverview = () => {
  return (
    <div>
      {courses.map((course, index) => (
        <CourseOverview
          key={index}
          title={course.title}
          title2={course.title2}
          description={course.description}
        />
      ))}
    </div>
  );
}

export default DataEngineerCourseOverview;
