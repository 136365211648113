import React from 'react';
import CourseOverview from '../ReUsableComponent/CourseOverview';

const courses = [
    {
        title: "Data Analyst",
        title2: "Training Program",
        description: "Dive into the essential skills of data analysis with our comprehensive program, spanning Excel, SQL, Power BI, and Python. Over three months, you will master data manipulation, visualization techniques, and advanced analytics through hands-on projects and practical exercises. From Excel basics to complex SQL queries, and from Power BI dashboards to Python programming, this course equips you with the tools to transform data into actionable insights. Engage in real-world case studies and collaborative projects that reinforce your learning. Join us to unlock the power of data and drive informed business decisions!"
      },
];

const DataanalystCourseOverview = () => {
  return (
    <div>
      {courses.map((course, index) => (
        <CourseOverview
          key={index}
          title={course.title}
          title2={course.title2}
          description={course.description}
        />
      ))}
    </div>
  );
}

export default DataanalystCourseOverview;
