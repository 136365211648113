import React from "react";
import ProgramForYou from "../ReUsableComponent/ProgramForYou";

const DataEngineerProgramForYou = () => {
    const programData = [
      {
        title: "I am a College Graduate looking for a Job in Data Engineer."
      },
      {
        title: "I am a recent graduate or fresher eager to start my career in data."
      },
      {
        title: "Professionals looking to transition from other IT roles into Data Engineer."
      },
      {
        title: "Individuals wanting to enhance their existing data skills and learn new tools."
      },
      {
        title: "Data Analysts who want to deepen their technical skills to become Data Engineer."
      },
    ];
  
    return (
      <ProgramForYou
        mainTitle="Who Can Apply for this Course?"
        subTitle="Whether you're starting your career or looking to enhance your skills, our program is tailored to help you succeed in the field of Data Engineer."
        applyText="Apply now"
        checkText="Is this program right for me?"
        programData={programData}
      />
    );
  };
  
  export default DataEngineerProgramForYou;