import React from "react";
import CourseFee from "../ReUsableComponent/CourseFee";

const DataEngineerCourseFee = () => {
    return (
      <CourseFee
        courseTitle="Master Data Engineer"
        coursePrice="59,999"
        courseDiscountedPrice="44,999"
        specialNote="Special price applicable for the next 15 seats only."
         courseEndDate="Closing Soon !!!"
        courseStartDate="Next Monday !"
        timeCommitment="08-10 hours/week"
      />
    );
  };
  
  export default DataEngineerCourseFee;