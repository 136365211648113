import React from "react";
import VideoComponent from "../ReUsableComponent/VideoComponent";

const DataanalystVideo = () => {
 
    const testingData = {
        videoUrl: "https://youtu.be/CgkrM7Q4taw",
        courseDuration: "3 MONTHS",
        courseTitle: "Become a Data Analyst",
        courseDescription: "This comprehensive Data Analyst course encompasses both foundational and advanced skills essential for data-driven decision-making. Over 40 sessions, you will master Excel for data manipulation, SQL for querying databases, and Power BI for data visualization. Gain hands-on experience with Python and its libraries, including Pandas and Matplotlib, to analyze and visualize data effectively. The curriculum covers data cleaning, transformation, and advanced analytics techniques through practical exercises and projects. Engage with real-world case studies that empower you to turn complex data sets into actionable insights, equipping you for a successful career in data analysis.",
        expertsCount: 5,
        sessionsCount: 20,
        courseImage: "/Images/DataAnalyst/DataAnalyst.jpg"
      };
    
      return <VideoComponent {...testingData} />;

};

export default DataanalystVideo;