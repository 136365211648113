import { Helmet } from "react-helmet";
import { useMediaQuery } from '@mui/material';
import ContactForm from "../ContactForms/CoursesContactForm";
import DataEngineerCourseOverview from "./DataEngCourseOverview";
import DataEngineerVideo from "./DataEngineerVideo";
import DataEngineerCourseContent from "./DataEngineerCourseContent";
import DataEngineerProgramForYou from "./DataEngineerProgramForYou";
import DataEngineerSkillsList from "./DataEngineerSkills";
import DataEngineerSampleCerticate from "./DataEngineerSampleCertificate";
import Feedback from "../ReUsableComponent/Testimonials";
import DataEngineerCourseFee from "./DataEngineerCourseFee";


const DataEngineer = () => {
    const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DataEngineer</title>
      </Helmet>
      {isMobile && <ContactForm />}
      <DataEngineerCourseOverview/>
      <DataEngineerVideo/>
      <DataEngineerCourseContent/>
      <DataEngineerProgramForYou/>
      <DataEngineerSkillsList/>
      <DataEngineerSampleCerticate/>
      <Feedback/>
      <DataEngineerCourseFee/>
    </div>
  );
};

export default DataEngineer;
