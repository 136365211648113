import { Box, IconButton, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import React from "react";

const TrainerDetails = () => {
  return (
    <Box
      sx={{
        width: "100%", // Full width for the entire component
        backgroundColor: "#171421", // Background color for the container
        // backgroundColor: "red",
        padding: "32px", // Padding around the container for spacing
        display: "flex", // Flexbox layout
        flexDirection: "column", // Stack the content vertically
        alignItems: "center", // Center all content horizontally
        marginTop: "-80px",
      }}
    >
      {/* Main Heading */}
      <Typography
  variant="h4"
  sx={{
    color: "#fff", // White color for the heading text
    fontFamily: "'Plus Jakarta Sans', sans-serif", // Custom font family
    fontSize:"25px" , // Font size responsive for mobile and desktop
    fontWeight: "bold", // Bold text
    position: "relative", // Position for pseudo-element
    display: "inline-block", // Inline-block display for better layout control
    "&::after": {
      content: "''", // Creates a pseudo-element after the text
      position: "absolute", // Absolute positioning to control placement
      left: "-50%" ,  // Adjust position for mobile and desktop
      bottom: "-20px", // Vertical offset for the curved effect
      width:  "200%", // Dynamic width for mobile vs desktop
      height: "10px", // Height of the curved underline
      backgroundColor: "#008000", // Green color for the underline
      borderRadius: "50%", // Creates the curved effect on the bottom
    },
  }}
>
  Our MainFrame Trainer
</Typography>


      {/* Trainer Details Section */}
      <Box
        sx={{
          display: "flex", // Horizontal layout for the left and right boxes
          justifyContent: "space-between", // Space between left and right box
          width: "100%", // Full width for the trainer details section
          marginTop:"30px",
        }}
      >
        {/* Left Box (Image with Gradient) */}
        <Box
          sx={{
            flex: 0.4, // Left box takes up 50% of the available space
            marginRight: "16px", // Right margin for spacing between boxes
            padding: "16px", // Padding inside the left box
            display: "flex", // Flexbox layout to center the image
            justifyContent: "center", // Center the image horizontally
            alignItems: "center", // Center the image vertically
            position: "relative", // Position the small box at the bottom of the image
            marginTop: "20px",
          }}
        >
          {/* Small Box with name and author */}
          <Box
            sx={{
              position: "absolute", // Position the small box at the bottom
              bottom: "40px", // Distance from the bottom of the container
              left: "65%", // Horizontally center the box
              transform: "translateX(-50%)", // Adjust to center the box
              borderRadius: "8px", // Border radius for rounded corners
              padding: "5px", // Padding inside the small box
              // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Optional box shadow
              zIndex: 1, // Ensure the small box is on top of the image
              textAlign: "center", // Center the text and icons inside the box
              width: "40%",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "2px" }}>
              Hari Praksh
            </Typography>
            <Typography variant="body2" sx={{ fontStyle: "italic", marginBottom: "5px" }}>
              MainFrame Developer
            </Typography>

            {/* LinkedIn and Gmail Icons */}
            <Box sx={{ display: "flex", justifyContent: "center", gap: "16px" }}>
              <IconButton href="https://www.linkedin.com/in/hariprakashy" target="_blank" sx={{ color: "#0077b5" }}>
                <LinkedInIcon />
              </IconButton>

              <IconButton href="mailto:hariprakashy@gmail.com" target="_blank" sx={{ color: "#ea4335" }}>
                <MailIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Image */}
          <img
            src="/Images/MainFrame/Trainer.jpg" // Replace with actual image URL
            alt="Trainer"
            style={{
              width: "80%", // Make the image take up the full width
              height: "auto", // Maintain aspect ratio
              borderRadius: "8px", // Optional: Add border-radius to make the image rounded
              marginTop: "80px,"
            }}
          />
        </Box>

        {/* Right Box */}
        <Box
          sx={{
            flex: 1, // Right box takes up 1 part of available space
            marginLeft: "16px", // Left margin for spacing between left and right box
            padding: "16px", // Padding inside the right box
            display: "flex", // Flexbox for layout
            flexDirection: "column", // Stack the content vertically
            justifyContent: "center", // Center content vertically
            textAlign: "center", // Align the text to the left
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "8px", color: "white" ,fontSize: "2rem", fontFamily: "'Roboto', sans-serif",}}>
            About Hari Praksh
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 1.6, color: "white" ,fontSize: "1.25rem", fontFamily: "'Roboto', sans-serif",}}>
            Hari Praksh has over 10 years of experience in Mainframe technology and the IT industry. He is certified in COBOL, JCL, and DB2, and has a deep understanding of various mainframe tools including COBOL, VSAM, Agile, JCL, CICS, DB2, JIRA, MVS OS/390, SCLM, Control-M, and more. Along with his technical expertise, Hari is passionate about teaching communication skills and helping people prepare for interviews. He has worked with many individuals, making complex topics easier to understand. His goal is to empower others with the knowledge and confidence to succeed, both in IT and in their professional lives.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TrainerDetails;
