import { Helmet } from "react-helmet";
import { useMediaQuery } from '@mui/material';
import ContactForm from "../ContactForms/CoursesContactForm";
import DataanalystCourseOverview from "./DataAnalystCourseOverview";
import DataanalystVideo from "./DataAnalystVideo";
import DataAnalystCourseContent from "./DataAnalystCourseContent";
import DataAnalystProgramForYou from "./DataAnalystProgramForYou";
import DataAnalystSkillsList from "./DataAnalystSkill";
import DataAnalystSampleCertificate from "./DataAnalystSampleCertificate";
import Feedback from "../ReUsableComponent/Testimonials";
import DataAnalystCourseFee from "./DataAnalystCourseFee";



const DataAnalyst = () => {
    const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DataAnalyst</title>
      </Helmet>
      {isMobile && <ContactForm />}
        <DataanalystCourseOverview/>
        <DataanalystVideo/>
        <DataAnalystCourseContent/>
        <DataAnalystProgramForYou/>
        <DataAnalystSkillsList/>
        <DataAnalystSampleCertificate/>
        <Feedback/>
        <DataAnalystCourseFee/>
    </div>
  );
};

export default DataAnalyst;
