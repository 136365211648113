import React from 'react';

const Copyright = () => {
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <p style={styles.text}>
          Copyright &copy; TECHJOB SOLUTIONS {currentYear}. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '20px 0',
    textAlign: 'center',
    bottom: 0,
    width: '100%',
  },
  container: {
    margin: '0 auto',
    padding: '0 20px',
  },
  text: {
    margin: 0,
    fontSize: '18px',
    letterSpacing: '0.5px',
  },
};

export default Copyright;
