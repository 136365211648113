import React from "react";
import CourseCurriculum from "../ReUsableComponent/CourseCurriculum";

const DataAnalystCourseContent = () => {
  // Define week content for the Data Engineer course
  const dataAnalystWeekContent = [
    {
        heading: "Excel",
        points: [
          "Introduction to Excel, Basics of Spreadsheets ",
          "Excel Formulas and Functions",
          "Data Entry, Data Validation, and Conditional Formatting ",
          "Sorting, Filtering, and Data Cleaning",
          "Working with Tables and Data Ranges",
          "Pivot Tables and Pivot Charts",
          "Advanced Formulas (LOOKUP, INDEX, MATCH)",
        ],
      },
      {
        heading: " SQL",
        points: [
          "Introduction to Databases and SQL ",
          "Basic SQL Queries (SELECT, WHERE, ORDER BY) ",
          "Aggregate Functions (COUNT, SUM, AVG, MIN, MAX) ",
          "Grouping Data (GROUP BY, HAVING) ",
          "Joins (INNER JOIN, LEFT JOIN, RIGHT JOIN, FULL JOIN) ",
          "Subqueries and Nested Queries",
          "Data Manipulation (INSERT, UPDATE, DELETE)",
        ],
      },
      {
        heading: "SQL & Data Projects",
        points: [
          "Creating and Modifying Tables (CREATE, ALTER, DROP) ",
          "Indexes, Views, and Stored Procedures",
          "Practical Exercises and Case Studies in SQL",
        ],
      },
      {
        heading: "Power BI",
        points: [
          "Introduction to Power BI, Interface Overview",
          "Connecting to Data Sources",
          "Data Transformation and Cleaning (Power Query Editor)",
          "Creating and Customizing Visuals",
          "Building Interactive Dashboards",
          "DAX Basics (Data Analysis Expressions)",
          "Advanced DAX Functions",
        ],
      },
      {
        heading: "Power BI Projects",
        points: [
          "Power BI Service (Publishing and Sharing) ",
          "Power BI Mobile and Power BI Report Server",
          "Practical Exercises and Case Studies in Power BI",
        ],
      },
      {
        heading: "Basic Python",
        points: [
          "Introduction to Python, Setup, and Basics ",
          "Data Types, Variables, and Basic Operations ",
          "Control Structures (if, for, while)",
          "Functions and Modules ",
          "Working with Lists, Tuples, and Dictionaries ",
          "File Handling (Reading and Writing Files) ",
          "Introduction to Numpy (Arrays and Basic Operations)",
        ],
      },
      {
        heading: "Python Continuation",
        points: [
          "Numpy Advanced Operations",
          "Introduction to Pandas (DataFrames and Series)",
          "DataFrame Operations (Selection, Filtering)",
          "Data Cleaning and Preparation",
          "Practical Exercises and Case Studies in Pandas",
        ],
      },
      {
        heading: "Matplotlib",
        points: [
          "Introduction to Matplotlib, Basic Plots",
          "Customizing Plots (Labels, Legends, Colors)",
          "Advanced Plots (Histograms, Pie Charts, Scatter Plots)",
          "Plotting Data from Pandas DataFrames",
          "Practical Exercises and Case Studies in Matplotlib ",
        ],
      },
      {
        heading: "Integrated Projects",
        points: [
          "Project 1 - Excel Data Analysis Project",
          "Project 2 - SQL Data Querying Project ",
          "Project 3 - Power BI Dashboard Project ",
          "Project 4 - Python Data Analysis Project",
          "Project 5 - Integrating Python and SQL",
        ],
      },
      {
        heading: "Review & Feedback",
        points: [
          "Review and Q&A - Excel ",
          "Review and Q&A - SQL",
          "Review and Q&A - Power BI ",
          "Review and Q&A - Python",
          "Final Project Presentation and Feedback",
        ],
      },
  ];

  return (
    <div>
      <CourseCurriculum weekContent={dataAnalystWeekContent} />
    </div>
  );
};

export default DataAnalystCourseContent;
