import React from "react";
import CourseCurriculum from "../ReUsableComponent/CourseCurriculum";

const DataEngineerCourseContent = () => {
  // Define week content for the Data Engineer course
  const dataEngineerWeekContent = [
    {
      heading: "SQL",
      points: [
        "Introduction, RDBS Concepts, Types of SQL Statements (Day 1)",
        "Types of SQL Statements (Day 2), Data Types, Operators, Keywords",
        "Aggregate Functions, Constraints",
        "Joins, Subqueries",
        "Advanced SQL Functions, Views, Indexes",
        "Stored Procedures, Functions",
        "Window Functions, CTEs",
        "Triggers and Query Optimization Techniques",
      ],
    },
    {
      heading: "Databricks",
      points: [
        "Introduction, Cluster Configuration, Tool Overview, Notebooks",
      ],
    },
    {
      heading: "PYTHON",
      points: [
        "Introduction, Programming Basics",
        "Data Types, Data Structures",
        "Conditional and Looping Statements",
        "Functions and Object-Oriented Programming",
        "File Handling and Input/Output",
        "List Comprehension and Lambda Expressions",
        "Regular Expressions",
        "Modules and Logging",
        "Exception Handling",
      ],
    },
    {
      heading: "DWH (Data Warehousing)",
      points: [
        "Fundamentals, Facts and Dimensions",
        "Change Data Capture, SCD, Incremental, History Loads",
      ],
    },
    {
      heading: "Databricks/SPARK",
      points: [
        "Spark Overview, Spark Architecture, In-Memory Processing",
        "Widgets, DBUtil Commands, Mount Point, Secret Scope, Azure Key-Vault Intro",
        "Spark Transformations and Actions",
        "RDD/DataFrames Read and Write Operations - 1",
        "RDD/DataFrames Read and Write Operations - 2",
        "Delta Lake, Temp Views, Spark SQL",
        "Implementing SCD Type 1 and Type 2 Use Cases",
      ],
    },
    {
      heading: "Azure",
      points: [
        "Cloud Fundamentals, Azure Account Creation, Portal Walkthrough",
        "Overview of Azure Services like Storage Account, Key Vault",
      ],
    },
    {
      heading: "ADF (Azure Data Factory)",
      points: [
        "Introduction to ADF (Integration Runtime, Linked Services, Datasets, Pipelines)",
        "Control Flow Activities, Data Flow, ADF Integration with Git",
        "ADF Hands-On",
        "Variables, Parameters, Parameterization Concept, Triggers",
      ],
    },
    {
      heading: "Azure DevOps",
      points: [
        "Azure Branches & Boards",
        "Azure CI/CD Pipeline",
      ],
    },
    {
        heading: "Azure Functions and Logic Apps",
        points: [
          "Introduction to Azure Functions/Logic Apps",
          "Understanding Serverless Architecture",
          "Creating Your First Azure Function",
          "Integrating Functions with Other Azure Services",
          "Logic Apps vs. Azure Functions: Use Cases",
          "Monitoring and Debugging Azure Functions",
          "Best Practices for Developing Serverless Solutions",
          "Implementing Triggers and Actions in Logic Apps",
        ],
      },
      {
        heading: "Azure Synapse",
        points: [
          "Introduction and Overview of SQL Pools and Spark Pools",
          "Synapse Use Cases Hands-On",
          "Loading Data into Synapse from Various Sources",
          "Data Integration with Azure Data Factory",
          "Building Analytical Solutions with Synapse Analytics",
          "Understanding Security and Access Control in Synapse",
          "Performance Optimization Techniques",
          "Creating and Managing Synapse Workspaces",
          "Exploring Serverless SQL Pools",
        ],
      },
      {
        heading: "Q&A",
        points: [
          "Q&A Session",
          "Additional Q&A Session",
          "Common Challenges Faced by Data Engineers",
          "Discussion on Best Practices in Data Engineering",
          "Resources for Further Learning and Development",
          "Networking Opportunities in the Data Engineering Community",
          "Real-World Scenarios and Problem-Solving",
          "Feedback on Course Content and Learning Experience",
        ],
      },
  ];

  return (
    <div>
      <CourseCurriculum weekContent={dataEngineerWeekContent} />
    </div>
  );
};

export default DataEngineerCourseContent;
