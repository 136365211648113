import React from "react";
import ProgramForYou from "../ReUsableComponent/ProgramForYou";

const DataAnalystProgramForYou = () => {
    const programData = [
      {
        title: "I am a college graduate looking for my first job in data analysis."
      },
      {
        title: "I am a recent graduate eager to start my career in data analytics."
      },
      {
        title: "Professionals looking to transition from other fields into data analysis."
      },
      {
        title: "Individuals wanting to enhance their existing data skills and learn new tools."
      },
      {
        title: "Data enthusiasts who want to deepen their analytical skills and become Data Analysts."
      },
    ];
  
    return (
      <ProgramForYou
        mainTitle="Who Can Apply for this Course?"
        subTitle="Whether you're starting your career or looking to enhance your skills, our program is tailored to help you succeed in the field of Data Analysis."
        applyText="Apply now"
        checkText="Is this program right for me?"
        programData={programData}
      />
    );
};

export default DataAnalystProgramForYou;
